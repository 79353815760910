import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import PortfolioContext from "../../context/context";

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom" id="footer">
      <Container>
        <Container>
          <span className="back-to-top">
            <Link to="hero" smooth duration={2000}>
              <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
            </Link>
          </span>
        </Container>

        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || "https://github.com/cobidev/gatsby-simplefolio"}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || "refresh"} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <Row>
          <Col>
            <h2 className="footer__title">Correo electrónico</h2>
            <a href="mailto:contacto@konter.com.mx" className="footer__info">contacto@konter.com.mx</a>
          </Col>
          <Col>
            <h2 className="footer__title">Teléfono</h2>
            <a href="https://wa.me/9994425592" className="footer__info">9994425592</a>
          </Col>
          <Col>
            <h2 className="footer__title">Dirección</h2>
            <p className="footer__info">Calle 1ᴴ 90, Residencial Montecristo, 97133 Mérida, Yuc.</p>
          </Col>
        </Row>
        <div>
          <p className="footer__text">
            <a href="privacidad">AVISO DE PRIVACIDAD</a>
          </p>
          <p className="footer__text">
            <a href="terminos">TÉRMINOS Y CONDICIONES</a>
          </p>
        </div>

        <p className="footer__text">
          © Konter Copyright {new Date().getFullYear()} - Todos los derechos reservados
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
