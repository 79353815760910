import React from "react";
import "../../style/components/_slide.scss";

const Slide = ({ title, img }) => (
  <div className="slide-container">
    <div className="slide-title">
      <h1>{title}</h1>
    </div>
    <div className="slide-icon">
          <img src={img} alt="Icon" />
        </div>
  </div>
);

export default Slide;
