import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import PortfolioContext from "../../context/context";
import laptop from "../../images/laptop.png";
import heroLeft from "../../images/hero-left-elements.png";
import heroRight from "../../images/hero-right-elements.png";
import heroFinancial from "../../images/LogoHero.png";


const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade
          left={isDesktop}
          bottom={isMobile}
          duration={1000}
          delay={500}
          distance="30px"
        >
          <img className="hero-left" src={heroLeft} alt="Elementos Konter"/>
          <h1 className="hero-title">
            {title || "Tus finanzas hechas simple."}
          </h1>
          <img className="hero-image" src={heroFinancial} alt="Laptop" />
          
          <img className="hero-right" src={heroRight} alt="Elementos Konter"/>
        </Fade>
        <Fade
          left={isDesktop}
          bottom={isMobile}
          duration={1000}
          delay={1000}
          distance="30px"
        ></Fade>
      </Container>
    </section>
  );
};

export default Header;
