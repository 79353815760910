import React, { useState, useEffect } from "react";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Projects from "./Projects/Projects";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Review from "./Review/Review";
import Faq from "./Faq/Faq";
import Table from "./Table/Table";
import HeaderNav from "./HeaderNav/HeaderNav";

import { PortfolioProvider } from "../context/context";


import {
  heroData,
  aboutData,
  projectsData,
  contactData,
  footerData,
} from "../mock/data";

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [review] = useState({});
  const [faq] = useState({});
  const[table] = useState({});
  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider
      value={{
        hero,

        projects,
        contact,
        footer,
        review,
        faq,
        table,
      }}
    >
      <HeaderNav />
      <Hero />
     
      <Projects />
      <Table />
      <Faq />
      <Review />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
