import React from "react";
// import PortfolioContext from "../../context/context";
import Title from "../Title/Title";
import Slide from "../Slide/Slide";
import book from "../../images/icons/book.png";
import bill from "../../images/icons/paper-bill.png";
import telephone from "../../images/icons/telephone.png";
import time from "../../images/icons/time.png";
import suitcase from "../../images/icons/suitcase.png";
import hammer from "../../images/icons/hammer.png";
import chart from "../../images/chart.png"
import imageWorks from "../../images/imageWorks.png"

const Projects = () => {
  // const { projects } = useContext(PortfolioContext);
  // const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = projects;
  // const [isDesktop, setIsDesktop] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   if (window.innerWidth > 769) {
  //     setIsDesktop(true);
  //     setIsMobile(false);
  //   } else {
  //     setIsMobile(true);
  //     setIsDesktop(false);
  //   }
  // }, []);

  return (
    <div className="container-projects" id="how-works">
      <section id="projects">
      <Title title="Konter es tu mano derecha en términos contables y financieros." />
          <span className="about-subtitle">
            Lleva el control de tus operaciones y mantente al día con tus declaraciones.
          </span>
          <img className="image-about" src={chart} alt="About" />
        <Title title="¿Cómo Funciona?" />
        <div className="slider">
          <div className="slide-track">
            <Slide title="Genera reportes de pérdidas y ganancias" img={book}/>
            <Slide title="Genera reportes de flujo de efectivo" img={bill} />
            <Slide title="Comunícate con tu asesor contable" img={telephone} />
            <Slide title="Obtén un reporte de tu situación fiscal" img={hammer} />
            <Slide title="Mantente al día con tus declaraciones" img={time} />
            <Slide title="Tus documentos en un solo lugar" img={suitcase} />
            {/* <Slide title="Toma el control de tu empresa" emoji="🕹️" /> */}
          </div>
        </div>
        <img className="image-works" src={imageWorks} alt="works" />
      </section>
    </div>
  );
};

export default Projects;
