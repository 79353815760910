import React  from 'react';
import { Container, Accordion, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Fade from 'react-reveal/Fade';
// import PortfolioContext from '../../context/context';

import "../../style/sections/_faq.scss";
import Title from "../Title/Title";
import imageHow from '../../images/imageHow.png'

const Faq = () => {
	// const { faq } = useContext(PortfolioContext);
	// //const { img, paragraphOne, paragraphTwo, paragraphThree, resume} = review;
	// const [isDesktop, setIsDesktop] = useState(false);
	// const [isMobile, setIsMobile] = useState(false);

	// useEffect(() => {
	// 	if (window.innerWidth > 769) {
	// 		setIsDesktop(true);
	// 		setIsMobile(false);
	// 	} else {
	// 		setIsMobile(true);
	// 		setIsDesktop(false);
	// 	}
	// }, []);

	return (
		
	<section className="faq">
		<img className="image-how" src={imageHow} alt="how" />
		<Title title="Preguntas Frecuentes" />
			<Container>
			<Fade bottom duration={800} delay={600} distance="40px">
				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='1' className="atoggle">
						¿Cómo me doy de alta en Konter?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='1' className="acollapse">
							<Card.Body>Para darte de alta solo tienes que crear tu usuario en el siguiente link: http://app.konter.com.mx/users/sign_in  y comunicarte con nosotros por el siguiente número de teléfono: 9994425249</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='2' className="atoggle">
						¿Cómo funciona konter financiero?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='2' className="acollapse">
							<Card.Body>La web app financiera es una herramienta para que lleves el control de las actividades de tu negocio. Por medio del llenado de la herramienta podrás conocer el estado actual de tu empresa en términos de Estado de resultados y flujo de efectivo.</Card.Body>
						</Accordion.Collapse>
					</Card>
					
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='3' className="atoggle">
						¿Qué es un flujo de efectivo?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='3' className="acollapse">
							<Card.Body>El flujo de efectivo nos arroja la capacidad monetaria con la que cuenta la empresa, de esta manera podremos saber si en negocio mismo tiene la posibilidad de cubrir sus necesidades futuras.</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='4' className="atoggle">
						¿Qué es un estado de resultados?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='4' className="acollapse">
							<Card.Body>El estado de resultados es el estado financiero que arroja las utilidades o perdidas que tuvo el negocio en un tiempo determinado, de esta manera podemos analizar si la empresa está siendo rentable.</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='5' className="atoggle">
						¿Cómo funciona Konter Fiscal?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='5' className="acollapse">
							<Card.Body>A través de nuestra plataforma tendrás un canal de comunicación directo con tu asesor contable. Por medio de este canal de comunicación el asesor solicitará los documentos pertinentes y se llevará a cabo tu contabilidad para entregarte tu declaración y en caso de ser así, el monto a pagar de tus impuestos y tu línea de captura. </Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='6' className="atoggle">
						¿Qué documentos necesita mi asesor contable para llevar a cabo mi contabilidad?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='6' className="acollapse">
							<Card.Body>Mes a mes el asesor requerirá los estados de cuenta de tu empresa para poder llevar a cabo tu contabilidad. Al inicio del servicio es necesario que proporciones tu nombre completo o la razón social de la empresa, el RFC y la FIEL.</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='7' className="atoggle">
						¿Qué es el RFC?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='7' className="acollapse">
							<Card.Body>El Registro Federal de Contribuyentes es una clave que requiere toda persona física o moral en México para realizar cualquier actividad económica lícita por la que esté obligada a pagar impuestos</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>

				<Accordion defaultActiveKey='0' className="ahover">
					<Card >
						<Accordion.Toggle as={Card.Header} eventKey='8' className="atoggle">
						¿Qué es la FIEL?
						</Accordion.Toggle>
						<Accordion.Collapse eventKey='8' className="acollapse">
							<Card.Body>La Firma electrónica o E.Firma es el conjunto de datos y caracteres que te identifica al realizar trámites y servicios por internet en el SAT, así como en otras Dependencias, Entidades Federativas, Municipios y la iniciativa privada. Tu e.firma es única, es un archivo seguro y cifrado, que tiene la validez de una firma autógrafa.</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</Fade>
			</Container>
		</section>
	);
};

export default Faq;
