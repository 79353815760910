import React from "react";
// import PortfolioContext from "../../context/context";
import Title from "../Title/Title";
import {useForm} from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Contact = () => {
  // const { contact } = useContext(PortfolioContext);
  const { register, errors, handleSubmit, reset } = useForm();

  const toastifySuccess = () => {
    toast('Mensaje enviado!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,  
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  const onSubmit = async (data) => {
    try {
      const templateParams = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        text: data.text
      }

      await emailjs.send(
        'service_dmvxdlu',
        'template_xm6m67l',
        templateParams,
        'user_QcTop9944vxYiW5xFj9d4'

      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }

  }

  return (
    <section id="contact">
        <Title title="¿Cómo te podemos ayudar?" />

        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <input type="text" className="contact-input" placeholder="Nombre completo" name="name"
          ref={register({
            required: { value: true, message: 'Ingresa tu nombre' },
            maxLength: {
              value: 50,
              message: 'Porfavor use menos de 50 caracteres'
            }
          })}
          />
          {errors.name && <span className='errorMessage'>{errors.name.message}</span>}

          <input type="email" className="contact-input" placeholder="Correo Electrónico" name="email"
          ref={register({
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          })}
          />
          {errors.email && (
            <span className='errorMessage'>Porfavor ingrese un correo electrónico valido.</span>
          )}

          <input type="tel" className="contact-input" placeholder="Teléfono" name="phone"
          ref={register({
            required: false,
            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
          })}
          />
          {errors.phone && (
            <span className='errorMessage'>Porfavor ingrese un teléfono valido.</span>
          )}
          
          <textarea className="contact-textarea" placeholder="Mensaje" name="text"
          ref={register({
            required: true
          })}
          ></textarea>
          {errors.text && <span className='errorMessage'>Porfavor ingrese un mensaje</span>}

          <input type="submit" value="Enviar" className="contact-btn"/>
        </form>
        <ToastContainer />
    </section>
  );
};

export default Contact;
