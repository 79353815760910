import React from "react";
import Fade from "react-reveal/Fade";
// import PortfolioContext from "../../context/context";
import SlideReview from "../SlideReview/SlideReview";
import ImperialToys from '../../images/imperialtoys-logo.png';
import MDLogo from '../../images/LogoMDBienes.png';
import MedinaLogo from '../../images/LogoMedina.png';


const Review = () => {
  // const { review } = useContext(PortfolioContext);
  // //const { img, paragraphOne, paragraphTwo, paragraphThree, resume} = review;
  // const [isDesktop, setIsDesktop] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   if (window.innerWidth > 769) {
  //     setIsDesktop(true);
  //     setIsMobile(false);
  //   } else {
  //     setIsMobile(true);
  //     setIsDesktop(false);
  //   }
  // }, []);

  return (
    <div className="container-review">
      <section id="review">
      <Fade bottom duration={1000} delay={300} distance="0px">
        <div className="slider">
          <div className="slide-track">
            <SlideReview
              quote='"Usar Konter durante las últimas semanas me ha ayudado a
             poder registrar ordenadamente todas mis transacciones y detectar oportunidades en
            los informes que ofrece. Es preciso y útil a la hora de tomar decisiones importantes."'
              
              name="Oscar Galaz"
              business="Director General - Imperial Toys"
              logo={ImperialToys}
            />
            <SlideReview
              quote='"La asesoría brindada me ha ayudado a tener en orden mis finanzas y con un futuro prometedor. 
              Las herramientas que brinda Konter están diseñadas específicamente para analizar y obtener información necesaria sobre tus finanzas personales y las de tu negocio."'
              name="Gerardo Moran"
              business="Socio - Inmobiliaria MD"
              logo={MDLogo}
            />
            <SlideReview
              quote='"Konter ha sido clave en mi incursión al mundo de los negocios, todos los temas contables y 
              fiscales están en orden gracias a la asesoría que me han brindado y las herramientas que continuamente desarrollan para hacernos la vida más fácil"'
              name="Aaron Medina"
              business="Agente de ventas - Mueblería Medina"
              logo={MedinaLogo}
            />
          </div>
        </div>
        </Fade>
      </section>
    </div>
  );
};

export default Review;
