import React from "react";
import Fade from "react-reveal/Fade";
import { Row, Col, Card, Button } from "react-bootstrap";
// import PortfolioContext from "../../context/context";
import check from "./check.png";
import x from "./cancel.png";


const Table = () => {

  // const { about } = useContext(PortfolioContext);

  // const [isDesktop, setIsDesktop] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   if (window.innerWidth > 769) {
  //     setIsDesktop(true);
  //     setIsMobile(false);
  //   } else {
  //     setIsMobile(true);
  //     setIsDesktop(false);
  //   }
  // }, []);

  return (
    <div className="container-table" id="plans">
        <h1 className="title">Elige la mejor opción para tu negocio</h1>
        <Fade bottom duration={800} delay={600} distance="40px">
          <Row className="justify-content-lg-center">
            <Col>
            <Card className="card-plan">
                <Card.Body>
                  <h3 className="header-title text-center">Konter Básico</h3>
                  <h2 className="header-price text-center">$0 <span className="period">/ al mes</span></h2>
                  <hr/>
                  <ul>
                    <li> <img src={check} alt="Check Icon"/> Registro de ingresos, ventas, costos y gastos</li>
                    <li> <img src={check} alt="Check Icon"/> Reportes: flujo de efectivo y estado de resultados</li>
                    <li className="muted"> <img src={x} alt="X Icon"/> Manejo de sucursales</li>
                    <li className="muted"> <img src={x} alt="X Icon"/> Manejo de cuentas bancarias</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Directorio de proveedores, ingresos y egresos</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Asistencia via teléfonica y web chat</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Declaraciones fiscales</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Manejo de carpetas históricas y expediente fiscal</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Asesor contable asignado</li>
                    <li className="muted"><img src={x} alt="X Icon"/> 1 a 5 facturas mensuales</li>
                  </ul>
                  <Button href="#" className="btn-plan" block>CONTRATAR</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col >
            <Card className="card-plan">
                <Card.Body>
                  <h3 className="header-title text-center">Konter Financiero</h3>
                  <h2 className="header-price text-center">$250 <span className="period">/ al mes</span></h2>
                  <hr/>
                  <ul>
                    <li><img src={check} alt="Check Icon"/> Registro de ingresos, ventas, costos y gastos</li>
                    <li><img src={check} alt="Check Icon"/> Reportes: flujo de efectivo y estado de resultados</li>
                    <li><img src={check} alt="Check Icon"/> Manejo de sucursales</li>
                    <li><img src={check} alt="Check Icon"/> Manejo de cuentas bancarias</li>
                    <li><img src={check} alt="Check Icon"/> Directorio de proveedores, ingresos y egresos</li>
                    <li><img src={check} alt="Check Icon"/> Asistencia via teléfonica y web chat</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Declaraciones fiscales</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Manejo de carpetas históricas y expediente fiscal</li>
                    <li className="muted"><img src={x} alt="X Icon"/> Asesor contable asignado</li>
                    <li className="muted"><img src={x} alt="X Icon"/> 1 a 5 facturas mensuales</li>
                  </ul>
                  <Button href="#" className="btn-plan" block>CONTRATAR</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col >
              <Card className="card-plan">
                <Card.Body>
                  <h3 className="header-title text-center">Konter Fiscal</h3>
                  <h2 className="header-price text-center">$500 <span className="period">/ al mes</span></h2>
                  <hr/>
                  <ul>
                    <li><img src={check} alt="Check Icon"/> Registro de ingresos, ventas, costos y gastos</li>
                    <li><img src={check} alt="Check Icon"/> Reportes: flujo de efectivo y estado de resultados</li>
                    <li><img src={check} alt="Check Icon"/> Manejo de sucursales</li>
                    <li><img src={check} alt="Check Icon"/> Manejo de cuentas bancarias</li>
                    <li><img src={check} alt="Check Icon"/> Directorio de proveedores, ingresos y egresos</li>
                    <li><img src={check} alt="Check Icon"/> Asistencia via teléfonica y web chat</li>
                    <li><img src={check} alt="Check Icon"/> Declaraciones fiscales</li>
                    <li><img src={check} alt="Check Icon"/> Manejo de carpetas históricas y expediente fiscal</li>
                    <li><img src={check} alt="Check Icon"/> Asesor contable asignado</li>
                    <li><img src={check} alt="Check Icon"/> 1 a 5 facturas mensuales</li>
                  </ul>
                  <Button href="#" className="btn-plan" block>CONTRATAR</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
        </Fade>
        
    </div>
  );
};

export default Table;