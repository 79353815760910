import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import imagenkonter from '../../images/imagenkonter.svg'
import Konter from '../../images/Konter.png'
import 'bootstrap/dist/css/bootstrap.css'
import { Link } from 'react-scroll'

function HeaderNav() {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top">
      <a class="navbar-brand" href="#">
        <img
          className="imgr"
          src={imagenkonter}
          height="40"
          width="40"
          alt="KonterLogo"
          loading="lazy"
        />
        <img src={Konter} height="40" />
      </a>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav className="align-items-center">
          <NavDropdown title="Acceder" id="collasible-nav-dropdown">
            {/*<NavDropdown.Item href="https://financiero.konter.com.mx/">
              Plataforma gratuita
            </NavDropdown.Item>*/}
            <NavDropdown.Item href="http://app.konter.com.mx/users/sign_in">
              App Konter
            </NavDropdown.Item>
          </NavDropdown>
          <Link to="how-works" smooth duration={1000} offset={80}>
            <Nav.Link>¿Cómo Funciona?</Nav.Link>
          </Link>
          <Link to="plans" smooth duration={1000}>
            <Nav.Link>Planes</Nav.Link>
          </Link>
          <Link to="contact" smooth duration={1000}>
            <Nav.Link>Contacto</Nav.Link>
          </Link>
          <Nav.Item>
            <Nav.Link style={{ textDecoration: 'none' }} href="https://www.konter.com.mx/blog">Blog</Nav.Link>
          </Nav.Item>
          {/*<Nav.Item>
            <Nav.Link style={{ textDecoration: 'none' }} href="https://facturacion.konter.com.mx/page/">Facturación</Nav.Link>
          </Nav.Item>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default HeaderNav
