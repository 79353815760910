import React from "react";
import "../../style/components/_slide-review.scss";

const SlideReview = ({ quote, name, business, logo }) => (
  <div className="slide-review-container">
      <div className="quote">
        <p>{quote}</p>
      </div>

      <div className="info-quote">
        <p className="name">{name}</p>
        <p className="business">{business}</p>
      </div>
      <div className="img-quote">
        <img className="slide-logo" src={logo} alt="Business Logo"/>
      </div>
      
  </div>
);

export default SlideReview;
